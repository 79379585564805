<template>
  <div class="home">
    <header>
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
        <div class="logoTxet">统一监控管理平台</div>
      </div>
      <div class="link">
        <div @click="chooseTask">
          选择任务 {{ taskInfo.name ? `(${taskInfo.name})` : "" }}
        </div>
        <el-divider direction="vertical"></el-divider>
        <div>{{ userName }}</div>
        <el-divider direction="vertical"></el-divider>
        <div @click="logOut">退出</div>
      </div>
    </header>
    <div class="banner">
      <img src="../assets/rk-banner.jpg" alt="" />
    </div>
    <main>
      <div v-for="item in projectList" :key="item">
        <div v-if="item">
          <div class="title">
            <div class="logo-info">{{ item.appCategory }}</div>
          </div>
          <div class="info-box">
            <div
              v-for="ele in item.apps"
              :key="ele"
              @click="toSysFn(ele.baseUrl, ele.isBindSat)"
            >
              <div class="project-logo">
                <img
                  v-if="ele.logo"
                  :src="`${baseUrl}/sys/icons/${ele.logo}`"
                  alt=""
                />
                <img v-else src="../assets/logo.png" alt="" />
              </div>
              <div class="project-name">
                <div>{{ ele.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <el-dialog
      :title="`任务列表 ${taskInfo.name ? `(${taskInfo.name})` : ''} `"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div style="margin-bottom: 10px">
        <el-input
          v-model="code"
          placeholder="请输入航天器代号"
          size="small"
          style="width: 180px; margin-right: 10px"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getSats"
          >查询</el-button
        >
      </div>
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        @current-change="handleCurrentChange"
        size="small"
      >
        <el-table-column prop="code" label="航天器代号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="name" label="航天器名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="containerName"
          label="航天器关联容器"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px; text-align: center">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="setFn">确定</el-button>
      </div>
    </el-dialog>
    <!-- <footer>Copyright© 单位单位单位单位单位单位单位单位</footer> -->
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      projectList: [],
      baseUrl: window.location.origin,
      dialogVisible: false,
      page: 1,
      pageSize: 10,
      code: "",
      tableData: [],
      multipleSelection: [],
      taskInfo: {name:''},
      userName:''
    };
  },
  methods: {
    logOut(){
      localStorage.clear()
      window.location.href=`${process.env.VUE_APP_BASE_SSO}?flag=logout`
    },
    getProjects() {
      this.$request("/sys/home/getApps").then((res) => {
        this.projectList = res.data;
      });
    },
    getSats() {
      this.$request("/sys/basic/satellite/getSatelliteList", {
        params: {
          page: this.page,
          pageSize: this.pageSize,
          code: this.code,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toSysFn(url, isBindSat) {
      if (isBindSat) {
        if (this.taskInfo.code) {
          window.open(`${url}?code=${this.taskInfo.code}`);
        } else {
          // this.$message.info("请选择任务");
        this.$message({
          showClose: true,
          message: '请选择任务',
          type: 'info'
        });
        }
      }else{
        window.open(`${url}`);
      }
    },
    chooseTask() {
      this.dialogVisible = true;
      this.code = "";
      this.page = 1;
      this.pageSize = 10;
      this.getSats();
      this.$nextTick(() => {
        this.$refs.singleTable.setCurrentRow(this.taskInfo);
      });
    },
    handleCurrentChange(val) {
      if(val){
     this.taskInfo = val;
      }
 
    },
    setFn() {
      this.dialogVisible = false;
    },
  },
  mounted() {
    this.getProjects();
    this.userName=JSON.parse(localStorage.getItem('userInfo')).name
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
  min-height: 100%;
  background: url(../assets/login-bg.png) repeat-y;
  background-size: 100% 100%;
  position: relative;
  padding-top: 60px;
  header {
    width: 100%;
    height: 60px;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20%;
    color: #fff;
    z-index: 10;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      .logoTxet {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .link {
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      font-size: 13px;
      font-weight: 900;
      > div {
        cursor: pointer;
      }
      .el-divider {
        cursor: unset;
        margin: 23px 20px 0 20px;
      }
    }
  }
  .banner {
    width: 60%;
    margin: 10px auto;
    img {
      width: 100%;
    }
  }
  main {
    width: 60%;
    margin: 0 auto;
    > div {
      border: 1px solid #c5d1e1;
      margin-bottom: 20px;
      position: relative;
      background: url(../assets/jb.jpg) no-repeat #ecf4ff;
      background-position: 50% 0;
    }
    .title {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 70px;
      height: 37px;
      background: url(../assets/title-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo-info {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        padding: 0 20px;
      }
    }
    .info-box {

      display: flex;
      flex-wrap: wrap;
      padding-top: 40px;
      padding-bottom: 10px;
      margin: 10px;
      background: #fff;
      > div {
        width: 23%;
         height: 108px;
        background: #fff;
        margin: 1% 1%;
        border: 1px solid #c8d3d7;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        .project-logo {
          width: 45%;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            width: 60px;
            height: 60px;
          }
        }
        .project-name {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          color: #3176bb;
        }
      }
      > div:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background: #eef9ff;
      }
    }
  }
  footer {
    width: 100%;
    height: 80px;
    color: #666;
    font-size: 16px;
    line-height: 80px;
    text-align: center;
  }
}
</style>
